/* 125% display scaling */
@media (-webkit-device-pixel-ratio: 1.25) {
    /* body {transform: scale(0.8);transform-origin:top left;width: 125%;height: 125%;} */
}

/* 150% display scaling */
@media (-webkit-device-pixel-ratio: 1.50) {
    /* body {transform: scale(0.667);transform-origin:top left;width: 150%;height: 150%;} */
}

/* 175% display scaling */
@media (-webkit-device-pixel-ratio: 1.75) {
    /* body {transform: scale(0.571);transform-origin:top left;width: 175%;height: 175%;} */
}

/* 200% display scaling */
@media (-webkit-device-pixel-ratio: 2) {
    /* body {transform: scale(0.5);transform-origin:top left;width: 200%;height: 200%;} */
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.chart-canvas {
    width: 100% !important;
}

.chart-container {
    overflow: auto;
}